import React from 'react'

import FormBlock from '../../components/mainComponents/FormBlock/FormBlock'
import ContactsBlock from '../../components/mainComponents/ContactsBlock/ContactsBlock'
import Seo from '../../components/Seo'

import contactsImage from '../../assets/images/jpg/seo/Contacts-min.jpg'

const Index = () => {
  return (
    <>
      <Seo
        title="Justice | contacts"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="Contact us via e-mail, phone, social networks or visit our comfortable office in the centre of Taganrog."
        image={contactsImage}
      />
      <div>
        <ContactsBlock />
        <FormBlock />
      </div>
    </>
  )
}

export default Index
