import React, { useState } from 'react'
import { usePosition } from 'use-position'
import {
  Map,
  Placemark,
  YMaps,
  ZoomControl,
  RouteButton,
} from 'react-yandex-maps'

import { PhoneAddressIcon } from './SvgComponents/PhoneAddressIcon'
import { AddressIconAddress } from './SvgComponents/AddressIconAddress'

import * as styles from './styles.module.scss'
import PhoneIcon from '../../../assets/images/svg/contactsBlock/icon1.svg'
import AddressIcon from '../../../assets/images/svg/contactsBlock/icon2.svg'
import EmailIcon from '../../../assets/images/svg/contactsBlock/icon3.svg'
import MediaIcon from '../../../assets/images/svg/contactsBlock/icon4.svg'
import placemarkLogo from '../../../assets/images/svg/contactsBlock/placemark icon.svg'

const apiKey = process.env.GATSBY_YANDEX_MAP_KEY

const Card = ({ src, title, text, href }) => (
  <div className={styles.contactsBlockCard}>
    <div className={styles.contactsBlockIcon}>
      <img src={src} alt='icon' />
    </div>
    <div>
      <p className={styles.contactsBlockTitle}>{title}</p>
      <a href={href} className={styles.contactsBlockText}>
        {text}
      </a>
    </div>
  </div>
)

const cardsArr = [
  {
    id: 1,
    title: 'Phone',
    text: '+7 (938) 417-17-44',
    src: PhoneIcon,
    href: 'tel:+79384171744',
  },
  {
    id: 3,
    title: 'Email',
    text: 'hello@it-justice.com',
    src: EmailIcon,
    href: 'mailto:hello@it-justice.com',
  },
]

const cardsArrAddress = [
  {
    id: 0,
    city: 'Taganrog',
    title: 'Address',
    text: 'Petrovskaya street, 94',
    src: AddressIcon,
    target: '_blank',
    href:
      'https://yandex.ru/maps/971/taganrog/?from=api-maps&ll=38.928251%2C47.216638&mode=routes&origin=jsapi_2_1_78&rtext=~47.216509%2C38.927900&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fll%3D38.928%252C47.217%26spn%3D0.001%252C0.001%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%25A0%25D0%25BE%25D1%2581%25D1%2582%25D0%25BE%25D0%25B2%25D1%2581%25D0%25BA%25D0%25B0%25D1%258F%2520%25D0%25BE%25D0%25B1%25D0%25BB%25D0%25B0%25D1%2581%25D1%2582%25D1%258C%252C%2520%25D0%25A2%25D0%25B0%25D0%25B3%25D0%25B0%25D0%25BD%25D1%2580%25D0%25BE%25D0%25B3%252C%2520%25D0%259F%25D0%25B5%25D1%2582%25D1%2580%25D0%25BE%25D0%25B2%25D1%2581%25D0%25BA%25D0%25B0%25D1%258F%2520%25D1%2583%25D0%25BB%25D0%25B8%25D1%2586%25D0%25B0%252C%252094&z=16',
    coordinate: [47.21650857429116, 38.92789999999999],
  },
  {
    id: 1,
    city: 'Rostov-on-Don',
    title: 'Address',
    text: 'Dolomanovskiy lane, 19',
    src: AddressIcon,
    target: '_blank',
    href:
      'https://yandex.ru/maps/?from=api-maps&ll=39.305233%2C47.216638&mode=routes&origin=jsapi_2_1_78&rtext=~47.220952%2C39.697621&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fll%3D39.698%252C47.221%26spn%3D0.001%252C0.001%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%25A0%25D0%25BE%25D1%2581%25D1%2582%25D0%25BE%25D0%25B2-%25D0%25BD%25D0%25B0-%25D0%2594%25D0%25BE%25D0%25BD%25D1%2583%252C%2520%25D0%2594%25D0%25BE%25D0%25BB%25D0%25BE%25D0%25BC%25D0%25B0%25D0%25BD%25D0%25BE%25D0%25B2%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9%2520%25D0%25BF%25D0%25B5%25D1%2580%25D0%25B5%25D1%2583%25D0%25BB%25D0%25BE%25D0%25BA%252C%252019&z=11.33',
    coordinate: [47.2210908, 39.697542],
  },
]

const initialPlacemarkOption = {
  iconLayout: 'default#image',
  iconImageHref: placemarkLogo,
  iconImageSize: [62, 72],
  iconImageOffset: [-16, -45],
}

const initialRouteButtonTypes = {
  auto: true,
  pedestrian: true,
  taxi: true,
  masstransit: true,
  bicycle: true,
}

const initialRouteButtonOptions = {
  float: 'right',
  visible: true,
  autofocus: true,
  expanded: true,
}

const zoom = 9

const geoCentre = {
  center: [47.2187996871455, 39.312720999999],
  zoom,
}

const initialColor = {
  0: {
    address: 'black',
    phone: 'black',
  },
  1: {
    address: 'black',
    phone: 'black',
  },
}

const ContactsBlock = () => {
  const [mouseActive, setMouseActive] = useState({ 0: false })
  const [color, setColor] = useState(initialColor)
  const [coordinate, setCoordinate] = useState([])
  const { latitude, longitude } = usePosition()
  const mapRef = React.useRef(null)

  const changeColor = (mouseActive) => {
    const colorTrigger = Object.entries(mouseActive).flat()
    if (colorTrigger[1]) {
      setMouseActive(mouseActive)
      setColor((prevState) => ({
        ...prevState,
        [colorTrigger[0]]: {
          address: 'rgba(57, 120, 244, 1)',
          phone: 'rgba(234, 22, 149, 1)',
        },
      }))
    } else {
      setMouseActive(mouseActive)
      setColor((prevState) => ({
        ...prevState,
        [colorTrigger[0]]: {
          address: 'black',
          phone: 'black',
        },
      }))
    }
  }

  const handleChange = (id, value) => changeColor({ [id]: value })

  // const showBalloon = (geoPointer) => {
  //   mapRef.current.setZoom(zoom + 1, { duration: 300 })
  //
  //   setTimeout(() => {
  //     mapRef.current.balloon.open(
  //       geoPointer.coordinate,
  //       {
  //         content:
  //           `<div class='${styles.balloonBody}' onClick='${setCoordinate(geoPointer.coordinate)}'>
  //          <h4>${geoPointer.city}</h4>
  //          <p>${geoPointer.text}</p>
  //          <div class='${styles.scheduleWrapper}'>
  //           <span>Schedule:</span>
  //           <span>08:00 - 18:00</span>
  //          </div>
  //         </div>`,
  //       },
  //     )
  //   }, 300)
  // }

  const initialRoute = {
    toEnabled: false,
    to: coordinate,
    from: [latitude, longitude],
    type: 'auto',
  }

  return (
    <div className={styles.contactsBlock}>
      <div className={`${styles.contactsBlockContainer} container`}>
        <div className={styles.contactsBlockContacts}>
          <h2>Contacts</h2>
          <div className={styles.contactsBlockCards}>
            {cardsArr.map(({ id, ...props }) => (
              <Card key={id} {...props} />
            ))}
            <div className={styles.contactsBlockCard}>
              <div className={styles.contactsBlockIcon}>
                <img src={MediaIcon} alt='icon' />
              </div>
              <div>
                <p className={styles.contactsBlockTitle}>Social media</p>
                <a
                  target='_blank'
                  href='https://vk.com/justice_it'
                  className={styles.contactsBlockText}
                  rel='noreferrer'
                >
                  Vk
                </a>
                /
                <a
                  target='_blank'
                  href='https://www.instagram.com/justice.it/'
                  className={styles.contactsBlockText}
                  rel='noreferrer'
                >
                  Instagram
                </a>
                /
                <a
                  target='_blank'
                  href='https://ru.linkedin.com/company/justice-team-it'
                  className={styles.contactsBlockText}
                  rel='noreferrer'
                >
                  Linkedin
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.contactsBlockMap}>
          <YMaps query={{ apikey: apiKey }}>
            <div>
              <Map
                className={styles.contactsBlockMapInner}
                defaultState={geoCentre}
                instanceRef={(ref) => {
                  ref && ref.behaviors.disable('scrollZoom')
                  mapRef.current = ref
                }}
              >
                {/*{cardsArrAddress.map((geoPointer) => {*/}
                {/*    return (*/}
                {/*      <Placemark*/}
                {/*        style={{ fill: 'red' }}*/}
                {/*        className={styles.contactsBlockMapPlacemark}*/}
                {/*        key={geoPointer.id}*/}
                {/*        geometry={geoPointer.coordinate}*/}
                {/*        options={initialPlacemarkOption}*/}
                {/*        onClick={() =>*/}
                {/*          showBalloon(geoPointer)*/}
                {/*        }*/}
                {/*      />*/}
                {/*    )*/}
                {/*  },*/}
                {/*)*/}
                {/*}*/}
                <ZoomControl />
                <RouteButton
                  instanceRef={(ref) => {
                    if (ref) {
                      ref.routePanel.state.set(initialRoute)
                      ref.routePanel.options.set({
                        types: initialRouteButtonTypes,
                      })
                    }
                  }}
                  options={initialRouteButtonOptions}
                />
              </Map>
            </div>
          </YMaps>
        </div>

        {/*{cardsArrAddress.map(({*/}
        {/*                        id, city,*/}
        {/*                        src, title,*/}
        {/*                        href, text,*/}
        {/*                      }) => (*/}
        {/*  <div*/}
        {/*    className={styles.contactsBlockCards}*/}
        {/*    onMouseOver={() => handleChange(id, true)}*/}
        {/*    onMouseOut={() => handleChange(id, false)}*/}
        {/*  >*/}
        {/*    <div className={styles.contactsBlockCard}>*/}
        {/*      <span*/}
        {/*        style={mouseActive[id]*/}
        {/*          ? { color: 'rgba(57, 120, 244, 1)' }*/}
        {/*          : { color: 'black' }}>*/}
        {/*        {city}*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*    <div className={styles.contactsBlockCard}>*/}
        {/*      <div className={mouseActive[id] ? `${styles.contactsBlockIcon} ${styles.contactsBlockIconActive}`: `${styles.contactsBlockIcon}`}>*/}
        {/*        <AddressIconAddress*/}
        {/*          color={color[id].address}*/}
        {/*          className={styles.contactsBlockCardIcon}*/}
        {/*          src={src}*/}
        {/*          alt='icon'*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <p className={styles.contactsBlockTitle}>{title}</p>*/}
        {/*        <a href={href} className={styles.contactsBlockText}>*/}
        {/*          {text}*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className={styles.contactsBlockCard}>*/}
        {/*      <div className={mouseActive[id] ? `${styles.contactsBlockIcon} ${styles.contactsBlockIconActive} ${styles.contactsBlockIconPink}`: `${styles.contactsBlockIcon}`}>*/}
        {/*        <PhoneAddressIcon*/}
        {/*          color={color[id].phone}*/}
        {/*          src={PhoneAddressIcon}*/}
        {/*          alt='icon' />*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <p className={styles.contactsBlockTitle}>Phone</p>*/}
        {/*        <a*/}
        {/*          target='_blank'*/}
        {/*          href='tel:+79375443707'*/}
        {/*          className={styles.contactsBlockText}*/}
        {/*          rel='noreferrer'*/}
        {/*        >*/}
        {/*          +7 (937) 544-37-07*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>
    </div>
  )
}

export default ContactsBlock
