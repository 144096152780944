// extracted by mini-css-extract-plugin
export var contactsBlock = "styles-module--contacts-block--AzikJ";
export var contactsBlockContainer = "styles-module--contacts-block-container--HMYJD";
export var contactsBlockContacts = "styles-module--contacts-block-contacts--hgPtj";
export var contactsBlockCards = "styles-module--contacts-block-cards--7K03t";
export var contactsBlockIcon = "styles-module--contacts-block-icon--3iwDR";
export var contactsBlockCard = "styles-module--contacts-block-card--EG8TZ";
export var contactsBlockIconActive = "styles-module--contacts-block-icon-active--VGqSU";
export var contactsBlockIconPink = "styles-module--contacts-block-icon-pink--xEmK8";
export var contactsBlockTitle = "styles-module--contacts-block-title--H0FEw";
export var contactsBlockText = "styles-module--contacts-block-text--2T+5d";
export var contactsBlockMap = "styles-module--contacts-block-map--243dk";
export var contactsBlockMapInner = "styles-module--contacts-block-map-inner--dWM6w";
export var balloonBody = "styles-module--balloonBody--pR1wd";
export var balloonButton = "styles-module--balloonButton--7kk2v";
export var scheduleWrapper = "styles-module--scheduleWrapper--5ydNv";